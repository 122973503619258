export const HeartInHand = () => (
    <div style={{display: "flex"}}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="white"/>
            <path
                d="M30.3964 4.72382C27.9105 2.23789 23.8801 2.23789 21.3942 4.72382C18.9084 2.23796 14.8779 2.23796 12.392 4.72382C9.90611 7.20967 9.90611 11.2401 12.392 13.726L20.4367 21.7708C20.9655 22.2996 21.8229 22.2996 22.3517 21.7708L30.3965 13.726C32.8822 11.2401 32.8822 7.20974 30.3964 4.72382Z"
                fill="#3EA3FB"/>
            <path
                d="M7.37524 28.3159L7.41214 28.2945C7.94271 27.987 8.38225 27.5487 8.71006 27.0305C9.29252 26.1097 10.1005 25.3125 11.1096 24.7299C13.9776 23.0741 17.5342 23.6919 19.6926 26.024L23.9751 27.2024C25.0077 27.4865 25.65 28.515 25.4521 29.5676C25.2424 30.6833 24.1689 31.4187 23.0527 31.2112L19.5639 30.5627L19.9158 30.6281L23.0681 31.2141C24.3617 31.4546 25.6986 31.2252 26.8381 30.5673L32.8004 27.125C33.7446 26.5799 34.952 26.9034 35.4972 27.8476C36.0423 28.7918 35.7188 29.9992 34.7746 30.5444L28.2639 34.3033C25.4754 35.9133 22.1393 36.2791 19.0681 35.3117L18.6278 35.173C16.2188 34.4142 13.6478 34.3305 11.1946 34.9313L7.37524 28.3159Z"
                fill="#FBFDFF"/>
            <path d="M7.66382 26.9539L12.4007 35.1581L8.97469 37.1362L4.23782 28.9319L7.66382 26.9539Z" fill="#3EA3FB"/>
            <path
                d="M36.1016 27.4996C35.365 26.2238 33.7278 25.7851 32.4521 26.5217L26.4898 29.964C26.3266 30.0583 26.1581 30.1414 25.9863 30.2154C26.0525 30.0509 26.1041 29.8778 26.138 29.6972C26.4008 28.299 25.5322 26.9081 24.1606 26.5307L20.0647 25.4037C18.9198 24.2321 17.4047 23.4498 15.7761 23.1933C14.0545 22.9221 12.2733 23.2537 10.7612 24.1267C9.73779 24.7175 8.85891 25.5441 8.20957 26.523C7.99858 26.2553 7.61811 26.1754 7.31415 26.3508L3.88804 28.3289C3.72783 28.4214 3.61094 28.5737 3.56309 28.7524C3.51517 28.9311 3.54028 29.1215 3.63277 29.2816L8.36964 37.4861C8.49888 37.7099 8.73323 37.8349 8.97434 37.8349C9.09263 37.8349 9.2126 37.8048 9.32238 37.7414L12.7485 35.7634C12.9087 35.6709 13.0256 35.5185 13.0734 35.3398C13.0762 35.3295 13.0779 35.3189 13.0802 35.3085C14.8706 35.1176 16.701 35.2979 18.4186 35.839L18.859 35.9777C20.0509 36.3531 21.2836 36.5384 22.513 36.5383C24.6346 36.5382 26.7454 35.9863 28.613 34.908L35.1238 31.1491C35.7418 30.7923 36.1838 30.2162 36.3686 29.5268C36.5531 28.8376 36.4584 28.1176 36.1016 27.4996ZM9.22892 36.1847L5.18952 29.1882L7.40762 27.9077L11.447 34.9041L9.22892 36.1847ZM35.0212 29.1658C34.9329 29.4953 34.7216 29.7705 34.4263 29.9411L27.9155 33.7C25.3034 35.2082 22.1551 35.5535 19.2781 34.6472L18.8378 34.5085C16.8055 33.8684 14.6308 33.6849 12.5194 33.9717L8.98271 27.8459C9.09612 27.7054 9.20249 27.5582 9.29985 27.4042C9.83642 26.5559 10.5829 25.8404 11.4587 25.3347C12.7132 24.6104 14.1311 24.3462 15.5591 24.5712C16.9468 24.7898 18.2332 25.4743 19.1812 26.4985C19.2695 26.5939 19.3827 26.6627 19.508 26.6972L23.7905 27.8756C24.4679 28.062 24.8969 28.749 24.7671 29.4395C24.6288 30.1756 23.9174 30.6632 23.1806 30.5262L19.6918 29.8777C19.3135 29.8074 18.949 30.0572 18.8786 30.4359C18.8083 30.8147 19.0582 31.1787 19.437 31.2491L22.9412 31.9005C24.397 32.171 25.905 31.9123 27.1874 31.172L33.1496 27.7297C33.7595 27.3778 34.5416 27.5874 34.8937 28.197C35.064 28.4924 35.1093 28.8365 35.0212 29.1658Z"
                fill="#0960E6"/>
            <path
                d="M9.87105 13.3667H11.1711C11.3892 13.6666 11.632 13.9518 11.8992 14.2191L19.944 22.2638C20.3315 22.6513 20.8466 22.8647 21.3947 22.8647C21.9427 22.8647 22.4578 22.6512 22.8453 22.2638L30.8901 14.219C31.1574 13.9518 31.4001 13.6666 31.6182 13.3666H33.3912C33.7764 13.3666 34.0887 13.0543 34.0887 12.6691C34.0887 12.2839 33.7764 11.9717 33.3912 11.9717H32.4065C32.7686 11.1138 32.9589 10.184 32.9589 9.22474C32.9589 7.33815 32.2241 5.56449 30.8902 4.23043C28.295 1.63564 24.1667 1.48582 21.3946 3.78147C18.6226 1.48582 14.4943 1.63564 11.8992 4.23057C10.5652 5.56462 9.83053 7.33829 9.83053 9.22488C9.83053 10.184 10.0209 11.1139 10.3829 11.9718H9.87105C9.48591 11.9718 9.17358 12.2841 9.17358 12.6693C9.17358 13.0545 9.48584 13.3667 9.87105 13.3667ZM21.8589 21.2775C21.735 21.4015 21.57 21.4698 21.3947 21.4698C21.2193 21.4698 21.0544 21.4015 20.9304 21.2775L13.0196 13.3667H16.1945C16.4546 13.3667 16.6932 13.2218 16.8133 12.9909L17.5439 11.586L19.8835 16.5721C19.9987 16.8178 20.2454 16.9733 20.5147 16.9733C20.5273 16.9733 20.5399 16.9729 20.5527 16.9722C20.8364 16.9568 21.0825 16.7708 21.1746 16.502L23.5487 9.57905L24.8376 12.9201C24.9414 13.1892 25.2 13.3666 25.4883 13.3666H29.7697L21.8589 21.2775ZM11.2255 9.22488C11.2255 7.71088 11.815 6.28755 12.8857 5.21701C13.9907 4.112 15.4421 3.55954 16.8936 3.55954C18.345 3.55954 19.7965 4.112 20.9015 5.21701C21.1739 5.4893 21.6154 5.4893 21.8879 5.21701C24.098 3.007 27.6938 3.00707 29.9037 5.21701C30.9743 6.28755 31.5639 7.71095 31.5639 9.22488C31.5639 10.202 31.317 11.1407 30.8551 11.9718H25.9669L24.161 7.29044C24.057 7.02108 23.7981 6.84399 23.5103 6.84399C23.506 6.84399 23.5014 6.84399 23.497 6.84413C23.204 6.84971 22.9456 7.03789 22.8505 7.3152L20.4142 14.4194L18.2074 9.71625C18.0947 9.47604 17.8554 9.32064 17.5902 9.3152C17.3238 9.30976 17.0795 9.45532 16.9571 9.69079L15.771 11.9717H11.9343C11.4724 11.1406 11.2255 10.202 11.2255 9.22488Z"
                fill="#0960E6"/>
        </svg>
    </div>
)
